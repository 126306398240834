<template>
  <div class="footer">
    <ul class="fade-in clearfloat p-lc-10 p-l-200-c view flex-row" v-if="langText == 2">
      <li  v-for="(item,index) in footData" :key="index" class="flex-1">
        <router-link :to="item.path" class="hor">
          <h5 >{{item.name}}</h5>
        </router-link>
        <router-link class="hor" :to="item1.path" v-for="(item1,index1) in item.child" :key="index1">
          <p >{{item1.name}}</p>
        </router-link>
      </li>
    </ul>
    <ul class="fade-in  view flex-row align-start justify-start p-l-10 p-r-10" style="margin-right:-15px;" v-else-if="langText == 1">
      <li v-for="(item,index) in footData" :key="index" class="flex-1 view align-start justify-start p-r-15">
        <router-link class="hor" :to="item.path">
          <h5 class="fzc-14">{{item.name}}</h5>
        </router-link>
        <router-link class="hor" :to="item1.path" v-for="(item1,index1) in item.child" :key="index1">
          <p class="fzc-12">{{item1.name}}</p>
        </router-link>
      </li>
    </ul>
    <img class="logo2img" src="../assets/images/Logo2.png" alt />

    <p class="cpright"><a style="color:#fff !important;" href="https://beian.miit.gov.cn">粤ICP备2020077372号</a>  深圳明日湾创创意咨询有限公司</p>
    <!-- <p>粤ICP备09148913号 粤公网安备 44010602000976 号 互联网出版许可证 新出证（粤）字032号 ICP许可证 B-20110253</p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    footData() {
      return this.$t("m.sidebarList");
    },
    langText() {     
      if (this.$t("m.langText") == '中' && window.innerWidth <= 768) {
        return 1
      }
      return 2
    },
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 99%;
  text-align: center;
  background: rgba(8,64,151,255);
  padding: 20px 40px 20px 0px;
  ul {

    li {
      float: left;
      font-weight: bold;
      // padding-left: 150px;
      h5 {
        font-weight: bold;
        color: #fff;
        font-size: 16px;
        margin-top: 25px;
      }
      p {
        font-size: 14px;
        margin-top: 14px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .logo2img {
    margin-top: 180px;
    width: 230px;
  }
  > p {
    color: #fff;
    margin-top: 20px;
  }
}

@media (min-width: 769px) { 
  .p-l-200-c {padding-left: 130px;
    padding-right: 170px;}
}
@media (max-width: 768px) {
  .footer {
    width: 100%;
    text-align: center;
    background: #0e4093;
    padding: 0;
  }
  .footer .disimg {
    padding-right: 0px;
    img {
      width: 60%;
    }
  }
  .p-lc-10 {padding-left: 10px;}
  .footer ul li {
    text-align: center;
    padding-left: 0rem;
  }
  .footer ul li h5{
    text-align: center;
  }
  .footer > p{
    font-size: 12px;
    padding-bottom:30px;
  }
  .footer .logo2img{
    // width: 100%;
  }
}
.fzc-14 {font-size: 14px !important;}
.fzc-12 {font-size: 12px !important;}
.cpright{
  font-size: 8px;
  padding-top: 30px;
}
.hor {
  transition: all ease .3s;
  cursor: pointer;
}
.hor:hover *{
  color: #f68732 !important;
}
</style>