<template>
  <div class="twoview">
    <Sidebar2 :sidebar-list="listsData"></Sidebar2>
    <div class="twoviewMain">
      <transition :name="transitionName">
        <router-view></router-view>
      </transition>
      <!-- <router-view></router-view> -->
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/footer.vue";
import Sidebar2 from "@/components/sidebar2.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.onHeaddata"),
      listsData: this.$t("m.sidebarList"),
      transitionName:'',
    };
  },
  components: {
    Foot,
    Sidebar2,
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  watch: {
    // 使用watch 监听$router的变化
    $route(to, from) {
      // 如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        // 设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.twoview {
  height: 100%;
  width: 100%;
  
}
@media (max-width: 768px) {
    .twoview{
      .twoviewMain{
        min-height: auto;
        >div{
           >div.header{
            padding: 0rem !important;
          }
          >div{
            min-height:auto !important;
            padding: 0.5rem !important;
            min-width: inherit !important;
            .title_zh{
              font-size: 0.56rem !important;
            }
            .title_en{
              font-size: 0.34rem !important;
            }
            .title_text{
              font-size: 0.3rem !important;
            }
          }
        }
      }
    }
}
</style>